/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { Box, Stack, Typography, Tooltip, IconButton, TextField } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useState } from "react";
import newAppts from "../../assets/images/authentication_v2/newAppts.svg";
import allAppts from "../../assets/images/authentication_v2/allAppts.svg";
import money from "../../assets/images/authentication_v2/money.svg";
import ero from "../../assets/images/authentication_v2/ero.svg";
import percentage from "../../assets/images/authentication_v2/percentage.svg";
import chart from "../../assets/images/authentication_v2/chart.svg";
import totalAppointment from "../../assets/images/authentication_v2/totalAppointment.svg";

function RoiChartItem({ label, desc, progress, isActive, onClick }) {
  const labelToIcon = {
    "Campaign Lead to Deal": percentage,
    "Campaign Lead to Follow Up": chart,
    "Campaign Lead to Offer": percentage,
    "Appointments to Deal": percentage,
    "Appointments to Follow Up": chart,
    "Appointments to Offer": percentage,
    "OIC Leads to Deal": percentage,
    "OIC Leads to Follow Up": chart,
    "OIC Leads to Offer": percentage,
    "Follow Up to Deal": chart,
    "Offer To Deal": chart,
    "Profitability Rating": allAppts,
    "Predictive Deal": newAppts,
    "Net Profit": money,
    "Total Revenue": money,
    ROI: newAppts,
    "Profitability (GOOD/BAD)": money,
    "Total Campaign Lead": newAppts,
    "Total Appointments": newAppts,
    "Total OIC Leads": newAppts,
    "Total Campaign Deals": totalAppointment,
    "Total Follow Ups": newAppts,
    "Total Offers": totalAppointment,
    "Follow Up to Offer": percentage,
    "Customer Acquisition Cost": ero,
  };

  const iconSrc = labelToIcon[label] || null;
  const [val, setVal] = useState();
  return (
    <Box
      width="100%"
      padding={2}
      onClick={onClick}
      sx={{
        backgroundColor: isActive ? "#e6e6e6" : "white",
        cursor: "pointer",
        border: "1px solid #d1d5db",
        borderRadius: "10px",
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            color: "#010101",
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "capitalize",
          }}
        >
          {label}
        </Typography>
        <Tooltip title={desc} arrow>
          <IconButton size="small">
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* <img src={iconSrc} alt="Icon" /> */}
      </Stack>
      <SuiBox mt={1}>
        <SuiTypography fontFamily="Poppins" fontSize="20px" fontWeight="bold" color="dark">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {label === "Net Profit"
              ? `${progress.currency}${Math.round(progress.content * 10 * val) / 100}`
              : progress.content}
            {label === "Net Profit" ? (
              <>
                <Stack
                  direction="row"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    padding: "2.5%",
                    border: "1px solid #e4e4e7",
                    width: "30%",
                  }}
                  alignItems="center"
                >
                  <input
                    type="number"
                    style={{
                      border: "0",
                      background: "white",
                      outline: "none",
                      width: "100%",
                    }}
                    inputProps={{ min: 0, max: 100 }}
                    variant="standard"
                    placeholder="%"
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 0 && value <= 100) {
                        setVal(value);
                      } else {
                        e.target.value = 0;
                      }
                    }}
                  />
                </Stack>
                {/* <TextField
                  type="number"
                  inputProps={{ min: 0, max: 100 }}
                  variant="standard"
                  placeholder="%"
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value >= 0 && value <= 100) {
                      setVal(value);
                    } else {
                      e.target.value = 0;
                    }
                  }}
                /> */}
              </>
            ) : (
              ""
            )}
          </Stack>
        </SuiTypography>
      </SuiBox>
    </Box>
  );
}

RoiChartItem.defaultProps = {
  desc: "",
};

RoiChartItem.propTypes = {
  label: PropTypes.string.isRequired,
  desc: PropTypes.string,
  progress: PropTypes.shape({
    currency: PropTypes.any,
    content: PropTypes.any.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  isActive: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
};

export default RoiChartItem;
