import React from "react";
import PropTypes from "prop-types";
import { Bar, Doughnut } from "react-chartjs-2";
import { Typography, Grid } from "@material-ui/core";
import {
  Chart as ChartJS,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Stack } from "@mui/material";

ChartJS.register(BarElement, ArcElement, CategoryScale, LinearScale, Title, Tooltip, Legend);
const StatsSection = ({ title, groups, stats }) => {
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      y: {
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const barColorsMapping = {
    total_num_of_booked_appointments: "#36a2eb", // blue
    potential_appointments: "#9de7c3", // green
    appointmentsLimit: "#FFBB28", // red
  };

  const doughnutColorsMapping = {
    new_appointments_or_oic_leads: "#7ec1ff", // purple
    num_of_approved_appointments: "#ff9455", // orange
    total_num_of_successful_appointments: "#9de7c3", // yellow
  };

  return (
    <Grid style={{ marginBottom: 40 }}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={1} style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {Object.entries(groups).map(([groupName, items]) => {
          let backgroundColor;
          if (groupName.includes("All")) {
            backgroundColor = items.map(
              (item) => barColorsMapping[item.key] || "rgba(75,192,192,0.6)"
            );
          } else if (groupName.includes("Booked") || groupName.includes("Processed")) {
            backgroundColor = items.map(
              (item) => doughnutColorsMapping[item.key] || "rgba(75,192,192,0.6)"
            );
          } else {
            backgroundColor = "rgba(75,192,192,0.6)";
          }

          const data = {
            labels: items.map((item) => item.label),
            datasets: [
              {
                label: groupName,
                weight: 9,
                cutout: 60,
                tension: 1,
                pointRadius: 2,
                borderWidth: 2,
                backgroundColor,
                data: items.map((item) => stats[item.key]),
                fill: false,
              },
            ],
          };

          return (
            <Grid
              key={groupName}
              item
              xs={12}
              sm={12}
              md={12}
              minWidth={{ md: "300px", sm: "150px", lg: "400px" }}
              maxWidth={{ md: "400px", sm: "150px", lg: "500px" }}
              style={{
                display: "flex",
                flexDirection: "column",
                borderTop: "2px solid rgb(228, 228, 231)",
                boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.05rem 1.6875rem 0rem",
                borderRadius: "10px",
                padding: "15px",
                flex: "1",
              }}
            >
              <Typography variant="h6" gutterBottom>
                {groupName}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: 50,
                  marginTop: 15,
                }}
              >
                {(groupName.includes("All") ||
                  groupName.includes("Booked") ||
                  groupName.includes("Processed")) && (
                  <Stack
                    height={{ sm: "150px", xs: "150px", lg: "200px", md: "280px" }}
                    width={{ sm: "100px", xs: "150px", lg: "200px", md: "450px" }}
                  >
                    {groupName.includes("All") ? (
                      <Bar data={data} options={chartOptions} />
                    ) : (
                      <Doughnut data={data} options={chartOptions} />
                    )}
                  </Stack>
                )}
                <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                  {items.map(({ label, key, color }) => (
                    <div
                      key={key}
                      style={{
                        borderRadius: 3,
                        padding: 2,
                        minWidth: 100,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                        <div
                          style={{
                            backgroundColor: `${color}`,
                            borderRadius: "12px",
                            padding: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                        <div>
                          <Typography variant="body2" sx={{ color: "#64748B", fontWeight: 500 }}>
                            {label}
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#0F172A" }}>
                            {stats[key]}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

StatsSection.propTypes = {
  title: PropTypes.string.isRequired,
  groups: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
};

const Dashboard = ({ campaignType, stats }) => {
  const appointmentsGroups = {
    // Campaigns: [{ label: "Active", key: "active_campaigns", color: "#36a2eb" }],
    "All Appointments": [
      { label: "Booked", key: "total_num_of_booked_appointments", color: "#36a2eb" },
      { label: "Potential", key: "potential_appointments", color: "#72edb1" },
      { label: "Target", key: "appointmentsLimit", color: "#ffb71a" },
    ],
    "Booked Appointments": [
      { label: "New", key: "new_appointments_or_oic_leads", color: "#3da2ff" },
      { label: "Approved", key: "num_of_approved_appointments", color: "#ff8136" },
      {
        label: "Successful",
        key: "total_num_of_successful_appointments",
        color: "#72edb1",
      },
    ],
  };

  const oicGroups = {
    // Campaigns: [{ label: "Active", key: "active_campaigns" }],
    "All OIC Leads": [
      { label: "Processed", key: "total_num_of_booked_appointments", color: "#36a2eb" },
      { label: "Target", key: "appointmentsLimit", color: "#ffb71a" },
    ],
    "Processed OIC Leads": [
      { label: "New", key: "new_appointments_or_oic_leads", color: "#3da2ff" },
      { label: "Approved", key: "num_of_approved_appointments", color: "#ff8136" },
    ],
  };

  let groupsMapping;
  let title;
  if (campaignType === "appointments") {
    groupsMapping = appointmentsGroups;
    title = "";
  } else if (campaignType === "oic") {
    groupsMapping = oicGroups;
    title = "";
  } else {
    return <Typography color="error">Invalid campaign type</Typography>;
  }

  return <StatsSection title={title} groups={groupsMapping} stats={stats} />;
};

Dashboard.propTypes = {
  campaignType: PropTypes.oneOf(["appointments", "oic"]).isRequired,
  stats: PropTypes.object.isRequired,
};

export default Dashboard;
