// eslint-disable-next-line import/no-extraneous-dependencies
// import { keyframes } from "@mui/system";
import { ThemeProvider, createTheme, keyframes } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f88865",
    },
  },
});

const dotAnimation = keyframes`
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
`;

const Dot = ({ delay }) => (
  <Box
    component="span"
    sx={{
      width: "0.8rem",
      height: "0.8rem",
      borderRadius: "50%",
      backgroundColor: "primary.main",
      display: "inline-block",
      margin: "0 0.25rem",
      animation: `${dotAnimation} 1.4s infinite ease-in-out both`,
      animationDelay: delay,
    }}
  />
);

Dot.propTypes = {
  delay: PropTypes.any.isRequired,
};

const Loader = () => (
  <ThemeProvider theme={theme}>
    <Stack mt="16%" flexDirection="column" alignItems="center">
      <Box>
        <Dot delay="0s" />
        <Dot delay="0.2s" />
        <Dot delay="0.4s" />
      </Box>
    </Stack>
  </ThemeProvider>
);

export default Loader;
