/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components

// Soft UI Dashboard PRO React base styles
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// Data
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SuiVectorMapRoot from "components/SuiVectorMap/SuiVectorMapRoot";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { dateToYMD } from "common/helpers";
import Swal from "sweetalert2";
import { CustomerUserContext } from "App";
import EventCalendarRoot from "components/Enrichment/components/Appointment/eventCalendar/EventCalendarRoot";
import PieChart from "examples/Charts/PieChart";
import SuiTypography from "components/SuiTypography";
import AppointmentModal from "../appointments/components/AppointmentModal";
import EventCalendar from "../appointments/components/Calendar";
import CalendarModal from "./CalendarModal";
import SubscriptionLink from "./SubscriptionLink";
// import DashboardCards from "./DashboardCards";

const EmbeddedAppointments = () => {
  const history = useHistory();
  const [customerUser] = useContext(CustomerUserContext);

  const [today, setToday] = useState(new Date());

  const [appointmentsVectorMap, setAppointmentsVectorMap] = useState({});
  const [campaign] = useState();
  const [campaignId] = useState(0);
  const [calendarData, setCalendarData] = useState([]);
  const [infoAppts, setInfoAppts] = useState(null);
  const [loadingCampaignItems, setLoadingCampaignItems] = useState(true);

  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const [selectedApointmentId, setSelectedApointmentId] = useState(null);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: { label: "", data: [] },
    backgroundColors: [],
  });

  const handleOpenAppointment = (pAppointmentId) => {
    history.push({
      pathname: "/enrichment",
      state: {
        appointmentIdParam: pAppointmentId,
        customerUser: { customerUser },
        campaignId: { campaignId },
        appointmentId: { selectedApointmentId },
      },
    });
  };
  const handleCloseAppointmentModal = () => {
    setSelectedApointmentId(null);
    setOpenAppointmentModal(false);
  };

  function CustomTabPanel(props) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const [value, setValue] = useState(0);

  useEffect(() => {
    const storedTabIndex = localStorage.getItem("activeTabIndex");
    if (storedTabIndex !== null) {
      setValue(parseInt(storedTabIndex, 10));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activeTabIndex", value.toString());
  }, [value]);

  CustomTabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const themeGrid = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "6px !important",
            boxShadow: "none",
            background: "none !important",
            minHeight: "390px",
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            borderRadius: "6px !important",
            boxShadow: "none",
            backgroundColor: "none !important",
            minHeight: "390px",
          },
        },
      },
    },
  });

  const [calendarLink, setCalendarLink] = useState("");
  const [reviewStatus] = useState(["info", "warning", "success", "error"]);

  const [filteredEvents, setFilteredEvents] = useState([]);
  const [dateClicked, setDateClicked] = useState("");

  const [isSubscriptionLinkOpen, setIsSubscriptionLinkOpen] = useState(false);

  const fetchAppointmentsCalendar = (generateNew = 0) => {
    const payload = {
      review_statuses: reviewStatus,
      generate_new: generateNew,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/updateCalendarICSFiletLink`,
        payload
      )
      .then((r) => {
        const { message } = r.data;
        setCalendarLink(message).then(() => {
          const payloadA = {
            review_statuses: reviewStatus,
            generate_new: 1,
          };
          axios
            .post(
              `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/updateCalendarICSFiletLink`,
              payloadA
            )
            .then((rA) => {
              const { messageA } = rA.data;
              setCalendarLink(messageA);
              fetchAppointmentsCalendar();
            });
        });
      })
      .catch(() => {});
  };
  const handleSubscription = () => setIsSubscriptionLinkOpen(true);

  const showAppointmentCalendar = () => {
    fetchAppointmentsCalendar();
    handleSubscription();
  };

  const fetchRes = (cols, res, pReviewStatus) => {
    setAppointmentsVectorMap([]);

    const rSet = res.data.data.filter(
      (rv) => pReviewStatus.indexOf(rv.class_name) > -1 || pReviewStatus === undefined
    );

    setTimeout(() => {
      setAppointmentsVectorMap(rSet);
    }, 400);

    const calendarDataVal = rSet.map((pRes) => ({
      id: `${pRes.id}`,
      title: `${pRes.lead_identificator}`,
      start: pRes.appointment_at,
      end: pRes.appointment_at,
      class_name: `${pRes.class_name}`,
      className: `${pRes.class_name}`,
      key: pRes.id,
    }));
    setCalendarData(calendarDataVal);

    setLoadingCampaignItems(false);
  };

  const fetchCampaignAppointmentsOrOics = (pCampaignId = 0, pReviewStatus) => {
    setLoadingCampaignItems(true);
    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaigns/${pCampaignId}/appointments/fields`
      )
      .then((r) => {
        const cols = r.data.data;

        axios
          .get(`/api/customers/${customerUser.customer.id}/campaigns/${pCampaignId}/appointments`)
          .then((res) => {
            fetchRes(cols, res, pReviewStatus);
          })

          .catch(() => {
            fetchRes(cols, null, pReviewStatus);
            setAppointmentsVectorMap([]);
            setLoadingCampaignItems(false);
          });
      })
      .catch(() => {});
  };
  const height = "16.250rem";
  const backgroundColors = ["#7ec1ff", "#ff9455", "#9de7c3"];

  const fetchCampaigns = () => {
    // setLoadingCampaigns(true);
    setLoadingCampaignItems(true);
    axios
      .get(`/api/customers/${customerUser.customer.id}/campaigns/appointments`)
      .then((res) => {
        const labels = [`New Appointments`, "Approved Appointments", "Successful Appointments"];
        setChartData({
          labels,
          datasets: {
            label: "Appointments",
            data: [
              res.data.info.new_appointments_or_oic_leads,
              res.data.info.num_of_approved_appointments,
              res.data.info.total_num_of_successful_appointments,
            ],
          },
        });
        setInfoAppts(res.data.info);
        // setInfoAppts([
        //   {
        //     icon: { color: "warning", component: "payment" },
        //     label: "new notes",
        //     progress: { content: res.data.info.new_messages },
        //   },
        //   {
        //     icon: { color: "info", component: "touch_app" },
        //     label: "New Appointments",
        //     progress: { content: res.data.info.new_appointments_or_oic_leads },
        //   },
        //   {
        //     icon: { color: "info", component: "touch_app" },
        //     label: "Successful Appointments",
        //     progress: { content: res.data.info.total_num_of_successful_appointments },
        //   },
        //   {
        //     icon: { color: "error", component: "extension" },
        //     label: "Active Campaigns",
        //     progress: { content: res.data.info.active_campaigns },
        //   },
        //   // {
        //   //   icon: { color: "primary", component: "library_books" },
        //   //   label: "All Leads",
        //   //   progress: { content: res.data.info.all_leads, percentage: 60 },
        //   // },
        //   {
        //     icon: { color: "info", component: "touch_app" },
        //     label: "Approved Appointments",
        //     progress: { content: res.data.info.num_of_approved_appointments },
        //   },
        //   // {
        //   //   icon: { color: "info", component: "touch_app" },
        //   //   label: "Target",
        //   //   progress: {
        //   //     content: res.data.info.appointmentsLimit,
        //   //     percentage: 90,
        //   //   },
        //   // },
        //   {
        //     icon: { color: "info", component: "touch_app" },
        //     label: "Potential Appointments",
        //     progress: {
        //       content: res.data.info.potential_appointments,
        //     },
        //   },
        // ]);
        // setLoadingCampaigns(false);
      })
      .catch(() => {
        // setLoadingCampaigns(false);
      });
  };

  const onClick = (selectedCard) => {
    if (selectedCard === "New Notes") {
      history.push({
        pathname: "/pages/chat",
        state: {
          from: "dashboard",
        },
      });
    } else if (selectedCard === "successfull Appointments") {
      history.push({
        pathname: "/appointments",
        state: {
          from: "successfull",
        },
      });
    } else if (selectedCard === "New Appointments") {
      history.push({
        pathname: "/appointments",
        state: {
          from: "new appointment",
        },
      });
    } else if (selectedCard === "Approved Appointments") {
      history.push({
        pathname: "/appointments",
        state: {
          from: "Approved Appointments",
        },
      });
    } else if (selectedCard === "Active Campaigns") {
      history.push({
        pathname: "/campaigns",
      });
    }
  };

  const queryParams = new URLSearchParams(window.location.search);

  const appointmentIdParam = queryParams.get("appointmentId");

  useEffect(() => {
    if (appointmentIdParam !== undefined && appointmentIdParam) {
      handleOpenAppointment(appointmentIdParam);
      // axios
      //   .get(
      //     `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentIdParam}/prospect-contacted`
      //   )
      //   .then(() => {
      //     handleOpenAppointment(appointmentIdParam);
      //   })
      //   .catch(() => {});
    }

    return () => {};
  }, [appointmentIdParam]);

  useEffect(() => {
    setToday(dateToYMD(new Date()));

    return () => {};
  }, [today]);

  useEffect(() => {
    fetchCampaigns();
    return () => {};
  }, [null, customerUser]);

  useEffect(() => {
    if (!openAppointmentModal) {
      fetchCampaignAppointmentsOrOics(campaignId, reviewStatus);
    }
    return () => {};
  }, [campaignId, customerUser, openAppointmentModal]);

  const handleRefreshList = () => {
    fetchCampaigns();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDateClick = (arg) => {
    const clickedDate = arg.dateStr;

    const filterArray = calendarData
      ? calendarData.filter((event) => event.start.split(" ")[0] === clickedDate)
      : [];
    setFilteredEvents(filterArray);
    setDateClicked(clickedDate);
    handleOpen();
  };

  const handleEventClick = (event) => {
    if (event.class_name.includes("info")) {
      Swal.fire({
        title: "Appointment Confirmation",
        text: "Do you want to confirm the appointment?",
        icon: "info",
        showCancelButton: true,
        // confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Yes, confirm it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const context = {
            is_confirmation_form: "yes",
            review_status: "accepted",
          };
          axios
            .post(
              `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${event.id}/update-review-status`,
              context
            )
            .then(() => {
              // Swal.fire("Success!", r.message, "success");
              handleOpenAppointment(event.id);
              fetchCampaignAppointmentsOrOics(campaignId, reviewStatus);
            })
            .catch((err) => {
              Swal.fire("Error!", err.message, "error");
            });
        }
      });
    } else if (event.class_name.includes("error")) {
      Swal.fire("Warning!", "You are not allowed to access the overview page", "warning");
    } else {
      handleOpenAppointment(event.id);
    }
  };

  const loader2 = (
    <Stack
      alignItems="center"
      className="loader"
      display="flex"
      justifyContent="center"
      height="40vh"
      width="100%"
    >
      <CircularProgress color="warning" /> Loading..
    </Stack>
  );

  const renderCampaignItems = () => {
    if (loadingCampaignItems) {
      return loader2;
    }
    if (campaign) {
      return (
        <Grid container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <SuiBox mb={3}>
                <Grid item xs={12} xl={12} sx={{ height: "max-content" }}>
                  {calendarData.length > 0 ? (
                    <EventCalendarRoot
                      header={{ title: "EmbeddedAppointments" }}
                      initialView="dayGridMonth"
                      timeZone="UTC"
                      initialDate={today}
                      events={calendarData}
                      eventClick={handleEventClick}
                      dateClick={handleDateClick}
                      showAppointmentCalendar={showAppointmentCalendar}
                      selectable
                      editable
                      // dayMaxEvents
                      dayMaxEventRows // for all non-TimeGrid views
                      views={{
                        dayGridMonth: {
                          dayMaxEventRows: 3,
                        },
                        timeGridWeek: {
                          dayMaxEventRows: 3,
                        },
                      }}
                      height="auto"
                    />
                  ) : (
                    ""
                  )}
                </Grid>

                <CalendarModal
                  openModal={open}
                  closeModal={handleClose}
                  events={filteredEvents}
                  handleEventClick={handleEventClick}
                  dateClicked={dateClicked}
                />
                <SubscriptionLink
                  openModal={isSubscriptionLinkOpen}
                  closeModal={setIsSubscriptionLinkOpen}
                  calendarLink={calendarLink}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container>
        <Grid container sx={{ backgroundColor: "white !important", borderRadius: "6px" }}>
          <Grid
            item
            // xs={12}
            md={8}
            lg={6}
            xl={5}
            sx={{ width: "100%", maxHeight: "96%", padding: "2px 2px 4px 2px" }}
          >
            {calendarData.length > 0 ? (
              <EventCalendar
                header={{ title: "EmbeddedAppointments" }}
                initialView="dayGridMonth"
                timeZone="UTC"
                initialDate={today}
                events={calendarData}
                eventClick={handleEventClick}
                dateClick={handleDateClick}
                showAppointmentCalendar={showAppointmentCalendar}
                selectable
                editable
                // dayMaxEvents
                dayMaxEventRows // for all non-TimeGrid views
                views={{
                  dayGridMonth: {
                    dayMaxEventRows: 3,
                  },
                  timeGridWeek: {
                    dayMaxEventRows: 3,
                  },
                }}
                height="auto"
              />
            ) : (
              ""
            )}
          </Grid>
          <ThemeProvider theme={themeGrid}>
            <Grid
              item
              md={6}
              xs={12}
              lg={6}
              xl={7}
              sx={{ width: "100%", height: "100%", padding: "2px 2px 4px 2px" }}
            >
              <SuiVectorMapRoot
                appointmentsOrOICLeads={appointmentsVectorMap ?? []}
                fetchCampaignAppointmentsOrOics={fetchCampaignAppointmentsOrOics}
                openAppointmetModal={handleEventClick}
                reviewStatus={reviewStatus}
                campaignId={campaignId}
              />
            </Grid>
          </ThemeProvider>
          <CalendarModal
            openModal={open}
            closeModal={handleClose}
            events={filteredEvents}
            handleEventClick={handleEventClick}
            dateClicked={dateClicked}
          />
          <SubscriptionLink
            openModal={isSubscriptionLinkOpen}
            closeModal={setIsSubscriptionLinkOpen}
            calendarLink={calendarLink}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {openAppointmentModal && selectedApointmentId ? (
        <AppointmentModal
          customerUser={customerUser}
          campaignId={campaignId}
          appointmentId={selectedApointmentId}
          open={openAppointmentModal}
          handleClose={handleCloseAppointmentModal}
          refreshList={handleRefreshList}
        />
      ) : (
        ""
      )}
      <SuiBox py={3}>
        <Grid container spacing={3} sx={{ zIndex: 100 }}>
          <Grid item xs={12} lg={12} mb={3}>
            <Grid container>
              <Grid item xs={12} />
              <Grid item xs={12}>
                {loadingCampaignItems ? (
                  loader2
                ) : (
                  <>
                    <div style={{ border: "1px solid rgb(228, 228, 231)", borderRadius: "10px" }}>
                      <Grid container>
                        <Grid item lg={5} md={12} sm={12} xs={12}>
                          <Typography
                            sx={{ display: "flex", justifyContent: "center" }}
                            mt={1}
                            variant="h6"
                          >
                            Appointments
                          </Typography>
                          <PieChart
                            title=""
                            height={height}
                            chart={chartData}
                            backgroundColors={backgroundColors}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          md={12}
                          sm={12}
                          xs={12}
                          my={1}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Grid paddingTop={{ sx: "0", lg: "40%" }}>
                            <Typography variant>
                              <SuiTypography
                                onClick={() => onClick("New Appointments")}
                                sx={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  color: "#7ec1ff",
                                  cursor: "pointer",
                                }}
                              >
                                New: {infoAppts?.new_appointments_or_oic_leads}
                              </SuiTypography>
                            </Typography>
                            <Typography variant>
                              <SuiTypography
                                onClick={() => onClick("Approved Appointments")}
                                sx={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  color: "#ff9455",
                                  cursor: "pointer",
                                }}
                              >
                                Approved: {infoAppts?.num_of_approved_appointments}
                              </SuiTypography>
                            </Typography>
                            <Typography variant>
                              <SuiTypography
                                onClick={() => onClick("successfull Appointments")}
                                sx={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  color: "#9de7c3",
                                  cursor: "pointer",
                                }}
                              >
                                Successfull:
                                {infoAppts?.total_num_of_successful_appointments}
                              </SuiTypography>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={5}
                          my={1}
                          sm={12}
                          sx={{ borderLeft: "1px dashed #ccc" }}
                        >
                          <Grid item my={1} lg={10} ml={2} mt={4}>
                            <Box
                              width="100%"
                              padding={1}
                              sx={{
                                cursor: "pointer",
                                border: "1px solid #e4e4e7",
                                borderRadius: "5px",
                              }}
                              onClick={() => onClick("New Notes")}
                            >
                              <Stack
                                direction="column"
                                // alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    color: "#71717a",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  New Notes
                                </Typography>
                              </Stack>
                              <SuiBox mt={1}>
                                <SuiTypography
                                  fontFamily="Poppins"
                                  fontSize="20px"
                                  fontWeight="bold"
                                  color="dark"
                                >
                                  {infoAppts?.new_messages}
                                </SuiTypography>
                              </SuiBox>
                            </Box>
                          </Grid>

                          <Grid item my={1} lg={10} ml={2}>
                            <Box
                              width="100%"
                              padding={1}
                              sx={{
                                border: "1px solid #e4e4e7",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => onClick("Active Campaigns")}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    color: "#71717a",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Active Campaigns
                                </Typography>
                              </Stack>
                              <SuiBox mt={1}>
                                <SuiTypography
                                  fontFamily="Poppins"
                                  fontSize="20px"
                                  fontWeight="bold"
                                  color="dark"
                                >
                                  {infoAppts?.active_campaigns}
                                </SuiTypography>
                              </SuiBox>
                            </Box>
                          </Grid>

                          <Grid item my={1} lg={10} ml={2}>
                            <Box
                              width="100%"
                              padding={1}
                              sx={{
                                border: "1px solid #e4e4e7",
                                borderRadius: "5px",
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    color: "#71717a",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Potential Appointments
                                </Typography>
                              </Stack>
                              <SuiBox mt={1}>
                                <SuiTypography
                                  fontFamily="Poppins"
                                  fontSize="20px"
                                  fontWeight="bold"
                                  color="dark"
                                >
                                  {infoAppts?.potential_appointments}
                                </SuiTypography>
                              </SuiBox>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
            {renderCampaignItems()}
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

export default EmbeddedAppointments;
