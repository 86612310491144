import React from "react";
import PropTypes from "prop-types";
import { Bar, Doughnut } from "react-chartjs-2";
import { Grid, Typography, Divider, Stack } from "@mui/material";

const CampaignChart = ({ campaign }) => {
  console.log("campaign", campaign);

  const barData = {
    labels: [/* "Raw Leads", */ "Appointments Limit", "Successful Appointments", "Difference"],
    datasets: [
      {
        label: "Campaign Data",
        weight: 9,
        cutout: 60,
        tension: 1,
        pointRadius: 2,
        borderWidth: 2,
        backgroundColor: ["#36a2eb", "#9de7c3", "#FFBB28"],
        data: [
          // campaign.num_of_raw_leads,
          campaign.appointmentsLimit,
          campaign.numOfSuccessfulAppointments,
          Math.abs(campaign.difference),
        ],
        fill: false,
      },
    ],
  };

  const campaignData = [
    {
      label: "Appointments Limit",
      key: "appointments_limits",
      color: "#36a2eb",
      data: campaign.appointmentsLimit,
    },
    {
      label: "Successful Appointments",
      key: "successful_appointments",
      color: "#9de7c3",
      data: campaign.numOfSuccessfulAppointments,
    },
    {
      label: "Difference",
      key: "difference",
      color: "#FFBB28",
      data: Math.abs(campaign.difference),
    },
  ];

  const pieData = {
    labels: [/* "Raw Leads", */ "Appointments Limit", "Successful Appointments", "Difference"],
    datasets: [
      {
        data: [
          // campaign.num_of_raw_leads,
          campaign.appointmentsLimit,
          campaign.numOfSuccessfulAppointments,
          Math.abs(campaign.difference),
        ],
        backgroundColor: ["#36a2eb", "#9de7c3", "#FFBB28"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      y: {
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>
            {campaign.start_date && (
              <Typography variant="body2" color="text.secondary">
                Start Date: {campaign.start_date}
              </Typography>
            )}
            <Typography
              color="rgb(52, 71, 103)"
              variant="body2"
              sx={{ mb: 2 }}
              dangerouslySetInnerHTML={{ __html: campaign.description }}
            />
            <Divider sx={{ my: 2 }} />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          minWidth={{ md: "300px", sm: "150px", lg: "400px" }}
          maxWidth={{ md: "400px", sm: "150px", lg: "500px" }}
          style={{
            display: "flex",
            flexDirection: "column",
            borderTop: "2px solid rgb(228, 228, 231)",
            boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.05rem 1.6875rem 0rem",
            borderRadius: "10px",
            padding: "15px",
            flex: "1",
          }}
        >
          <Typography variant="h6" style={{ fontSize: "1rem" }} gutterBottom>
            Campaign: {campaign.name} (Bar Chart)
          </Typography>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: 50,
              marginTop: 15,
            }}
          >
            <Stack
              height={{ sm: "150px", xs: "150px", lg: "200px", md: "280px" }}
              width={{ sm: "100px", xs: "150px", lg: "200px", md: "450px" }}
            >
              <Bar data={barData} options={options} />
            </Stack>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              {campaignData.map(({ label, key, color, data }) => (
                <div
                  key={key}
                  style={{
                    borderRadius: 3,
                    padding: 2,
                    minWidth: 100,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <div
                      style={{
                        backgroundColor: `${color}`,
                        borderRadius: "12px",
                        padding: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                    <div>
                      <Typography variant="body2" sx={{ color: "#64748B", fontWeight: 500 }}>
                        {label}
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: "bold", color: "#0F172A" }}>
                        {data}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          minWidth={{ md: "300px", sm: "150px", lg: "400px" }}
          maxWidth={{ md: "400px", sm: "150px", lg: "500px" }}
          style={{
            display: "flex",
            flexDirection: "column",
            borderTop: "2px solid rgb(228, 228, 231)",
            boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.05rem 1.6875rem 0rem",
            borderRadius: "10px",
            padding: "15px",
            flex: "1",
          }}
        >
          <Typography variant="h6" style={{ fontSize: "1rem" }} gutterBottom>
            Campaign: {campaign.name} (Pie Chart)
          </Typography>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: 50,
              marginTop: 15,
            }}
          >
            <Stack
              height={{ sm: "150px", xs: "150px", lg: "200px", md: "280px" }}
              width={{ sm: "100px", xs: "150px", lg: "200px", md: "450px" }}
            >
              <Doughnut data={pieData} options={options} />
            </Stack>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              {campaignData.map(({ label, key, color, data }) => (
                <div
                  key={key}
                  style={{
                    borderRadius: 3,
                    padding: 2,
                    minWidth: 100,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <div
                      style={{
                        backgroundColor: `${color}`,
                        borderRadius: "12px",
                        padding: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                    <div>
                      <Typography variant="body2" sx={{ color: "#64748B", fontWeight: 500 }}>
                        {label}
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: "bold", color: "#0F172A" }}>
                        {data}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

CampaignChart.propTypes = {
  campaign: PropTypes.object.isRequired,
};

export default CampaignChart;
