import { useContext, useEffect, useState } from "react";
import { CustomerUserContext } from "App";
import axios from "axios";
import OpenChat from "./OpenChat";
import DirectChat from "./DirectChat";
// import OpenSidebar from "../../layouts/pages/chat_v2/OpenSidebar";

function SendBird() {
  const [customerUser] = useContext(CustomerUserContext);

  const [sendBirdUser, setSendBirdUSer] = useState(null);

  const getSendbirdUser = () => {
    axios
      .post(`/api/get-customer/${customerUser?.customer.id}`)
      .then((response) => {
        setSendBirdUSer(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSendbirdUser();
  }, [customerUser]);

  return (
    <>
      {sendBirdUser && (
        <>
          <DirectChat sendBirdUser={sendBirdUser} />
          <OpenChat sendBirdUser={sendBirdUser} />
        </>
      )}
    </>
  );
}

// Typechecking props for

export default SendBird;
