import React from "react";
// import "@sendbird/uikit-react/dist/index.css";
import { ChatAiWidget } from "@sendbird/chat-ai-widget";
import "@sendbird/chat-ai-widget/dist/style.css";
import PropTypes from "prop-types";

const APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID;
const BOT_ID = process.env.REACT_APP_SENDBIRD_BOT_Id;

function SendBirdBot({ sendBirdUser }) {
  const USER_ID = sendBirdUser.user_id;

  return (
    <ChatAiWidget
      applicationId={APP_ID}
      botId={BOT_ID}
      userId={USER_ID}
      accessToken={sendBirdUser.access_token}
      // userNickName={sendBirdUser.nickname}
      // sessionToken={sessionToken}
      // configureSession={configureSession}
      createGroupChannelParams={{ name: sendBirdUser.nickname }}
      botStudioEditProps={{
        welcomeMessages: [
          {
            message: `Hello ${sendBirdUser.nickname}, welcome to our chat! How can I assist you today?`,
            suggestedReplies: ["I am facing issues on appointments", "I want to talk to agent"],
          },
        ],
      }}
    />
  );
}

SendBirdBot.propTypes = {
  sendBirdUser: PropTypes.any.isRequired,
};

export default SendBirdBot;
