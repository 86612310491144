import React, { useState, useContext } from "react";
import { Button, Grid, Stack } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/Navbar";
import Footer from "examples/Footer";
import EmbeddedAppointments from "layouts/dashboards/appointments_v2/EmbeddedAppointments";
import EmbeddedOicLeads from "layouts/dashboards/oic_v2/EmbeddedOicLeads";
import { useSoftUIController } from "context";
import { CustomerUserContext } from "App";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ActivityLogs from "./ActivityLogs";
import JotformModal from "./JotformModal";

const useStyles = makeStyles(() => ({
  tabs: {
    minHeight: "0 !important",
    "@media (max-width: 899px)": {
      marginBottom: "10px",
      maxWidth: "230px",
    },
  },
}));

const Dashboard = () => {
  const [controller] = useSoftUIController();
  const [customerUser] = useContext(CustomerUserContext);
  const [tabId, setTabId] = useState(0);

  // const handleClick = (id) => () => {
  //   setTabId(id);
  // };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const classes = useStyles();

  const layoutMarginLeft = controller.miniSidenav ? "0px" : "0px";
  const [style, setStyle] = useState({
    border: "0 !important",
  });

  const handleChange = (event, newValue) => {
    setTabId(newValue);
    setStyle({
      border: "0 !important",
    });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
    };
  }

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          flexContainer: {
            minWidth: "230px",
            padding: "0.28rem",
            background: "#EDEDED",
            borderRadius: "10px",
          },
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#5F5F5F",
            fontFamily: "Poppins",
            padding: "8px 16px 8px 18px",
            borderRadius: "10px",
            textTransform: "none",
            marginRight: "0",
            fontSize: "14px",
            fontWeight: "600",
            minHeight: "5px",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "10px",
              padding: "8px 16px 8px 18px",
              marginRight: "0",
              fontSize: "13px",
              fontWeight: "600",
            },
          },
        },
      },
    },
  });
  return (
    <DashboardLayout>
      <DashboardNavbar componentName="Dashboard" />
      <Grid container sx={{ backgroundColor: "#FBFAFB", overflow: "hidden", ml: layoutMarginLeft }}>
        {/* <ThemeProvider theme={theme}> */}
        <Grid container item spacing={3} sx={{ p: 3, backgroundColor: "#FFFFFF" }}>
          <Grid item lg={5} md={12} sm={12} xs={12}>
            <ActivityLogs customerId={customerUser.customer.id} />
          </Grid>
          <ThemeProvider theme={theme}>
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Grid
                display={{ lg: "flex", md: "flex", xs: "block", sm: "flex" }}
                pt={{ lg: 5, sm: 3, xs: 2 }}
                gap={2}
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  className={classes.tabs}
                  value={tabId}
                  onChange={handleChange}
                >
                  <Tab style={tabId === 0 ? style : {}} label="Appointments" {...a11yProps(0)} />
                  <Tab style={tabId === 1 ? style : {}} label="OIC" {...a11yProps(1)} />
                </Tabs>
                <Stack>
                  <Button
                    variant="success"
                    size="small"
                    style={{
                      color: "green",
                      border: "1px solid green",
                      height: "40px",
                      fontSize: "13px",
                    }}
                    onClick={handleOpen}
                  >
                    New Campaign
                  </Button>
                </Stack>
              </Grid>
              {open && <JotformModal open={open} setOpen={setOpen} />}
              {tabId === 0 ? (
                <EmbeddedAppointments customerUser={customerUser} />
              ) : (
                <EmbeddedOicLeads customerUser={customerUser} />
              )}
            </Grid>
          </ThemeProvider>
        </Grid>
        {/* </ThemeProvider> */}
        <Stack sx={{ width: "100%", flexShrink: "0" }}>
          <Footer />
        </Stack>
      </Grid>
    </DashboardLayout>
  );
};

export default Dashboard;
