import PropTypes from "prop-types";

export function CampaignIcon({ color = "currentColor", size = 35 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {/* Bar Chart */}
      <line x1="6" y1="4" x2="6" y2="10" />
      <line x1="12" y1="8" x2="12" y2="14" />
      <line x1="18" y1="12" x2="18" y2="18" />
    </svg>
  );
}

CampaignIcon.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CampaignIcon;
