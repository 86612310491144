/* eslint-disable import/no-cycle */
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { CustomerUserContext } from "App";
import "@sendbird/chat-ai-widget/dist/style.css";
import { GroupChannel } from "@sendbird/uikit-react/GroupChannel";
import "@sendbird/uikit-react/dist/index.css";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Drawer, Grid, IconButton, Typography } from "@mui/material";
import chat from "assets/images/enrichment/chat.svg";
import { useSoftUIController } from "context";
import SBProvider from "@sendbird/uikit-react/SendbirdProvider";
import GroupChannelHandler from "@sendbird/uikit-react/handlers/GroupChannelHandler";
import { useSendbird } from "@sendbird/uikit-react/";

function DirectChat({ sendBirdUser }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [totalUnreadCount, setTotalUnreadCount] = useState(null);
  const [currentChannelUrl, setCurrentChannelUrl] = useState(null);
  const [controller] = useSoftUIController();
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const useStyles = makeStyles(() => ({
    value: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: "400",
      color: "#4A4A4A",
      marginLeft: "10px",
    },
    container: {
      backgroundColor: "#ff4d00",
      borderRadius: "20px",
      width: "45px",
      height: "45px",
    },
    sidebar: {
      display: "flex",
      justifyContent: "end",
    },
    drawerPaper: {
      height: "640px",
      width: "400px",
      left: "auto",
      right: "100px",
      margin: 0,
      bottom: "0",
      borderRadius: "0",
      backgroundColor: "#ffffff",
    },
    messageDrawerPaper: {
      height: "50%",
      width: "20%",
      left: "auto",
      border: "1px solid #9595956b",
      margin: 0,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      // borderRadius: "20px 20px 0 0",
      top: "50px",
    },
    mainContainer: {
      position: "fixed",
      right: 100,
      transition: "bottom 0.2s ease-in-out",
      backgroundColor: "white",
      borderRadius: "20px 20px 0 0",
      bottom: sidebarOpen
        ? `${document.querySelector(".MuiDrawer-paperAnchorBottom").clientHeight}px`
        : "0",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      gap: "10px",
      padding: "6px",
      border: "1px solid #9595956b",
      width: "250px",
      cursor: "pointer",
    },
  }));

  const classes = useStyles();
  const [customerUser] = useContext(CustomerUserContext);
  const USER_ID = `customer_${customerUser?.customer?.id}`;
  const APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID;

  const CustomConnectionHandler = () => {
    const sb = useSendbird();
    const sdk = sb.state?.stores?.sdkStore?.sdk;

    useEffect(() => {
      if (sdk && sdk.groupChannel) {
        const handler = new GroupChannelHandler({
          onMessageReceived: (channel, message) => {
            console.log("Message received:", message);
            if (sidebarOpen === false) {
              setSidebarOpen(true);
              setCurrentChannelUrl(channel.url);
            }
          },
        });
        sdk.groupChannel.addGroupChannelHandler("UNIQUE_HANDLER_ID", handler);
        const query = sdk.groupChannel.createMyGroupChannelListQuery({
          customTypesFilter: ["admin"],
          // unreadChannelFilter: "unread_message",
          limit: 1,
        });
        query
          .next()
          .then((channels) => {
            if (channels.length > 0) {
              const { url, unreadMessageCount } = channels[0];
              setTotalUnreadCount(unreadMessageCount);
              setCurrentChannelUrl(url);
            }

            // const info = channels.map((channel) => ({
            //   url: channel.url,
            //   unreadCount: channel.unreadMessageCount,
            // }));
            // setChannelInfo(info);
          })
          .catch((error) => {
            console.error("Error fetching channels:", error);
          });
      }

      // Clean up the connection handler when the component unmounts
      return () => {
        if (sdk && sdk.groupChannel) {
          sdk.groupChannel.removeGroupChannelHandler("UNIQUE_HANDLER_ID");
        }
      };
    }, [sdk]);

    return null;
  };

  return (
    <>
      {currentChannelUrl && (
        <Grid
          item
          container
          sx={{ backgroundColor: "#FBFAFB", overflow: "hidden" }}
          ml={controller.miniSidenav ? "100px" : "300px"}
          md={controller.miniSidenav ? 10.8 : 9}
        >
          <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={toggleSidebar}>
            <div className={classes.mainContainer}>
              <div className={classes.container}>
                <div className={classes.image}>
                  <img src={chat} alt={chat} style={{ marginTop: "10px" }} />
                </div>
              </div>
              <Typography variant="h6" fontSize="1rem">
                Support Chat ({totalUnreadCount})
              </Typography>
            </div>
          </IconButton>
        </Grid>
      )}
      <div className={classes.sidebar}>
        <CssBaseline />
        <Drawer
          variant="persistent"
          anchor="bottom"
          open={sidebarOpen}
          style={{ width: "100%", maxWidth: "400px" }}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {sendBirdUser && (
            <SBProvider
              sdkInitParams={{ debugMode: true, logLevel: 0 }}
              appId={APP_ID}
              userId={USER_ID}
              accessToken={sendBirdUser.access_token}
            >
              <CustomConnectionHandler />
              {currentChannelUrl && <GroupChannel channelUrl={currentChannelUrl} />}
            </SBProvider>
          )}
        </Drawer>
      </div>
    </>
  );
}
DirectChat.propTypes = {
  sendBirdUser: PropTypes.any.isRequired,
};
export default DirectChat;
