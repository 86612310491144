import React, { useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Typography, Checkbox, Pagination, Select, MenuItem, Stack } from "@mui/material";
import { Grid } from "@material-ui/core";
import TableSort from "./TableSort";
import Star from "./iconComponents/Star";

const OrangeCheckbox = styled(Checkbox)(() => ({
  color: "#FF7701", // Orange color
  "&.Mui-checked": {
    color: "#FF7701", // Orange color when checked
  },
}));

function OicTable({
  table,
  openAppointment,
  handleFavoriteClick,
  isFavourite,
  searchLower,
  setSelectedItems,
  selectedItems,
}) {
  const useStyles = makeStyles(() => ({
    tableData: {
      height: "60vh",
      overflow: "auto",
      marginTop: "20px",
    },
    "@media (min-width: 1120px)": {
      tableData: {
        height: "80vh",
      },
    },
    TableContainer: {
      padding: "0 2%",
    },
    tableHead: {
      borderBottom: "0",
      margin: "0",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Poppins",
      color: "#010101",
    },
    table: {
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: "400",
      borderTop: "1px solid #5F5F5F26",
      color: "#010101",
      padding: "1.5% 3%",
    },

    tableRow: { borderBottom: "1px solid #50555C" },
    pagination: { float: "right", padding: "2% 0" },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: "center",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#626161",
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "Poppins",
            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: "#010101",
              fontSize: "14px",
              fontWeight: "700",
              fontFamily: "Poppins",
            },
          },
        },
      },
    },
  });

  const handleSelectAll = () => {
    if (selectedItems.length === table.rows.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(table.rows.map((item) => item.id));
    }
  };

  const handleCheckboxClick = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortingOrderBy, setSortingOrderBy] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  //   const handleFavoriteSort = () => {
  //     if (sortingOrderBy === "is_favorite") {
  //       setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
  //     } else {
  //       setSortingOrderBy("is_favorite");
  //       setSortingOrder("asc");
  //     }
  //   };

  const handleFavoriteSort = () => {
    if (sortingOrderBy === "is_favorite") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("is_favorite");
      setSortingOrder("asc");
    }
  };
  const handleStatusSort = () => {
    if (sortingOrderBy === "status") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("status");
      setSortingOrder("asc");
    }
  };
  const handleLeadSort = () => {
    if (sortingOrderBy === "lead_identificator") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("lead_identificator");
      setSortingOrder("asc");
    }
  };

  const handleCampaignSort = () => {
    if (sortingOrderBy === "campaign_name") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("campaign_name");
      setSortingOrder("asc");
    }
  };
  const handleCustomerNameSort = () => {
    if (sortingOrderBy === "customer_name") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("customer_name");
      setSortingOrder("asc");
    }
  };
  const handleCreatedAtSort = () => {
    if (sortingOrderBy === "created_at") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("created_at");
      setSortingOrder("asc");
    }
  };
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, table.rows.length - page * rowsPerPage);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [dropdownRowValue, setDropdownRowValue] = useState(rowsPerPage);

  // Function to handle dropdown change
  const handleDropdownRowChange = (event) => {
    setRowsPerPage(event.target.value > table.rows.length ? table.rows.length : event.target.value);
    // You can perform actions based on the selected value here
    setDropdownRowValue(event.target.value);
  };

  let filterRows;

  if (isFavourite === 1) {
    filterRows = table.rows.filter((item) => item.is_favorite === 1);
    if (searchLower) {
      filterRows = filterRows.filter((item) =>
        Object.values(item).some((value) => String(value).toLowerCase().includes(searchLower))
      );
    }
  } else if (searchLower) {
    filterRows = table.rows.filter((item) =>
      Object.values(item).some((value) => String(value).toLowerCase().includes(searchLower))
    );
  } else {
    filterRows = table.rows;
  }

  const paginationRows =
    rowsPerPage > 0
      ? stableSort(filterRows, getComparator(sortingOrder, sortingOrderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : stableSort(filterRows, getComparator(sortingOrder, sortingOrderBy));

  const paginationCount = Math.ceil(filterRows.length / rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.tableData}>
        <TableContainer className={classes.TableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <OrangeCheckbox
                    checked={selectedItems.length === table.rows.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableSort
                  align="left"
                  sorted={sortingOrderBy === "is_favorite" ? sortingOrder : "none"}
                  onClick={handleFavoriteSort}
                >
                  Fav
                </TableSort>
                <TableSort
                  justifyContent="center"
                  align="center"
                  sorted={sortingOrderBy === "status" ? sortingOrder : "none"}
                  onClick={handleStatusSort}
                >
                  Status
                </TableSort>
                <TableSort
                  justifyContent="center"
                  align="center"
                  sorted={sortingOrderBy === "lead_identificator" ? sortingOrder : "none"}
                  onClick={handleLeadSort}
                >
                  Lead
                </TableSort>
                <TableSort
                  justifyContent="center"
                  align="center"
                  sorted={sortingOrderBy === "campaign_name" ? sortingOrder : "none"}
                  onClick={handleCampaignSort}
                >
                  Campaign
                </TableSort>
                <TableSort
                  justifyContent="center"
                  align="center"
                  sorted={sortingOrderBy === "customer_name" ? sortingOrder : "none"}
                  onClick={handleCustomerNameSort}
                >
                  Customer Admin
                </TableSort>
                <TableSort
                  justifyContent="center"
                  align="center"
                  sorted={sortingOrderBy === "customer_name" ? sortingOrder : "none"}
                  onClick={handleCreatedAtSort}
                >
                  Created At
                </TableSort>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginationRows.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <OrangeCheckbox
                      checked={selectedItems.includes(item.id)}
                      onChange={() => handleCheckboxClick(item.id)}
                    />
                  </TableCell>
                  <TableCell
                    key={item.id}
                    className={classes.table}
                    scope="row"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleFavoriteClick(item);
                    }}
                  >
                    <Star fill={item.is_favorite === 1 ? "#EBCB24" : "transparent"} />
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    <Typography
                      onClick={() => openAppointment({ id: item.id, classNames: item.class_name })}
                      sx={{
                        backgroundColor: "#FF4D00D9",
                        color: "#fff",
                        textAlign: "center",
                        borderRadius: "5px",
                        padding: "9%",
                        fontSize: "12px",
                        cursor: "pointer",
                        width: "60px",
                        fontFamily: "Poppins",
                      }}
                    >
                      VIEW
                    </Typography>
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    {item.lead_identificator.replace("Firmenname: ", "")}
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    {item.campaign_name}
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    {item.customer_name}
                  </TableCell>
                  <TableCell key={item.id} className={classes.table} scope="row">
                    {item.created_at}
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Grid>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Select
            sx={{ height: "20px", width: "80px", border: "none", marginRight: "30px" }}
            value={dropdownRowValue}
            onChange={handleDropdownRowChange}
            displayEmpty
            inputProps={{ "aria-label": "Action" }}
            multiple={false}
            variant="standard"
          >
            <MenuItem value={5} checked={dropdownRowValue === 5}>
              5
            </MenuItem>
            <MenuItem value={10} checked={dropdownRowValue === 10}>
              10
            </MenuItem>
            <MenuItem value={25} checked={dropdownRowValue === 25}>
              25
            </MenuItem>
            <MenuItem value={50} checked={dropdownRowValue === 50}>
              50
            </MenuItem>
            <MenuItem value={100} checked={dropdownRowValue === 100}>
              100
            </MenuItem>
          </Select>
          <Pagination
            className={classes.pagination}
            count={paginationCount}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Stack>
      </Grid>
    </ThemeProvider>
  );
}

OicTable.propTypes = {
  table: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.array).isRequired,
  }).isRequired,
  openAppointment: PropTypes.func.isRequired,
  handleFavoriteClick: PropTypes.func.isRequired,
  isFavourite: PropTypes.number.isRequired,
  searchLower: PropTypes.any.isRequired,
  setSelectedItems: PropTypes.any.isRequired,
  selectedItems: PropTypes.any.isRequired,
};

export default OicTable;
