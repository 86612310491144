export const dateToYMD = (date) => {
  const d = date.getUTCDate();
  const m = date.getUTCMonth() + 1;
  const y = date.getUTCFullYear();
  return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`;
};

export const dateToYMDHHii = (date) => {
  const d = date.getUTCDate();
  const m = date.getUTCMonth() + 1;
  const y = date.getUTCFullYear();
  const h = date.getUTCHours();
  const i = date.getUTCMinutes();
  return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}T${h <= 9 ? `0${h}` : h}:${
    i <= 9 ? `0${i}` : i
  }:`;
};

export const pusherOptions = {
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: "eu",
  forceTLS: true,
  encrypted: true,
  authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
  // As I'm using JWT tokens, I need to manually set up the headers.
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      Accept: "application/json",
      "X-Selected-Customer-Id": localStorage.getItem("selectedCustomerId"),
    },
  },
};

export default "";
