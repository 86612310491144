/* eslint-disable no-shadow */
import React from "react";
import { Stack } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/Navbar";
import Footer from "examples/Footer";
import Campaign from "./Campaign";

const Campaigns = () => (
  <>
    <DashboardLayout>
      <DashboardNavbar componentName="Campaigns" />
      <Campaign />
      <Stack sx={{ width: "100%", flexShrink: "0", marginTop: "30px" }}>
        <Footer />
      </Stack>
    </DashboardLayout>
  </>
);

export default Campaigns;
