/* eslint-disable import/no-cycle */
import React, { useState, useContext, useEffect } from "react";
import { Button, Grid, Icon, Stack, Typography } from "@mui/material";
import { useSoftUIController } from "context";
import CampaignTypes from "common/constants/campaign-types";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Loader from "components/Loader/Loader";
import MultiSelect from "components/MultiSelect";
import { CustomerUserContext } from "App";
import { useHistory } from "react-router-dom";
import AppointmentIcon from "layouts/crm_dashboard/icons/AppointmentIcon";
import OicLeadIcon from "layouts/crm_dashboard/icons/OicLeadIcon";
import CampaignDashboard from "./Dashboard";
// import CampaignDataTable from "./Table/CampaignDataTable";
import JotformModal from "../dashboard/JotformModal";
import CampaignTable from "./Table";

const Campaign = () => {
  const [controller] = useSoftUIController();
  const history = useHistory();

  const [search, setSearch] = useState("");
  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const [customerUser] = useContext(CustomerUserContext);
  const [tabId, setTabId] = useState(
    history.location.state?.from === "oic_campaigns"
      ? CampaignTypes.OIC
      : CampaignTypes.APPOINTMENTS
  );
  const handleClick = (id) => () => {
    setTabId(id);
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const statusOptions = [
    { value: "pending", label: "Initialize" },
    { value: "in_progress", label: "In Progress" },
    { value: "completed", label: "Done" },
  ];

  const [status, setStatus] = useState(statusOptions.map((opt) => opt.value));

  const handleStatusChange = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      setStatus(selectedOptions.map((option) => option.value));
    } else {
      setStatus([]);
    }
  };

  const statusDisplayValue = statusOptions
    .filter((s) => status.includes(s.value))
    // eslint-disable-next-line no-shadow
    .map((status) => ({
      value: status.value,
      label: status.label,
    }));

  const useStyles = makeStyles(() => ({
    tableInfo: {
      width: "100%",
      float: "left",
    },
    calender: {
      width: "100%",
      float: "right",
      border: "1px solid white",
      paddingTop: "15px",
      overflow: "auto",
    },
    tabs: {
      minHeight: "0 !important",
      "@media (max-width: 800px)": {
        marginBottom: "10px",
      },
    },
    filters: {
      display: "flex",
      alignItems: "center",
    },
    datePicker: {
      marginLeft: "10px",
    },
    statusButton: {
      padding: "5px 35px",
      border: "0",
      borderRadius: "6px",
      cursor: "pointer",
      marginRight: "1%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "@media (max-width: 800px)": {
        marginBottom: "10px",
      },
    },
  }));
  const classes = useStyles();
  const [loadingInfoTable, setLoadingInfoTable] = useState(false);

  const [campaigns, setCampaigns] = useState([]);
  const [info, setInfo] = useState({});

  const refreshCampaignsDataTable = () => {
    setLoadingInfoTable(true);
    axios
      .get(`/api/customers/${customerUser.customer.id}/campaigns/${tabId}`)
      .then((res) => {
        const cCampaigns =
          tabId === CampaignTypes.OIC
            ? res.data.oicLeadCampaigns.data
            : res.data.appointmentCampaigns.data;
        setCampaigns(
          cCampaigns.filter((c) => statusDisplayValue.map((s) => s.value).includes(c.status))
        );
        setInfo(res.data.info);
      })
      .catch((error) => {
        console.error("Err", error.message);
      })
      .finally(() => {
        setLoadingInfoTable(false);
      });
  };

  useEffect(() => {
    refreshCampaignsDataTable();
  }, [tabId, status]);

  const layoutMarginLeft = controller.miniSidenav ? "0px" : "0px";

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "#FBFAFB",
          overflow: "hidden",
          ml: layoutMarginLeft,
          minHeight: "850px",
        }}
      >
        <Grid container item spacing={3} sx={{ p: 3, backgroundColor: "#FFFFFF" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid display={{ xs: "block", sm: "flex", md: "flex", lg: "flex" }}>
              <Stack
                sx={{
                  padding: "10px",
                  backgroundColor: tabId === CampaignTypes.APPOINTMENTS ? "#FF4D00D9" : "#FFFFFF",
                  borderRadius: "5px",
                  marginRight: "1%",
                  marginBottom: "1%",
                  border: "1px solid #e4e4e7",
                  cursor: "pointer",
                  color: tabId === CampaignTypes.APPOINTMENTS ? "#fff" : "#000000",
                  textAlign: "center",
                  gap: "10px",
                }}
                xs={{ flexGrow: 1 }}
                direction="row"
                alignItems="center"
                justifyContent="center"
                onClick={handleClick(CampaignTypes.APPOINTMENTS)}
              >
                <AppointmentIcon
                  width="20"
                  height="20"
                  color={tabId === CampaignTypes.APPOINTMENTS ? "#fff" : "#000000"}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  Appointments
                </Typography>
              </Stack>
              <Stack
                sx={{
                  padding: "10px",
                  backgroundColor: tabId === CampaignTypes.OIC ? "#FF4D00D9" : "#FFFFFF",
                  borderRadius: "5px",
                  marginRight: "1%",
                  marginBottom: "1%",
                  cursor: "pointer",
                  border: "1px solid #e4e4e7",
                  color: tabId === CampaignTypes.OIC ? "#fff" : "#000000",
                  textAlign: "center",
                  gap: "10px",
                }}
                xs={{ flexGrow: 1 }}
                direction="row"
                alignItems="center"
                justifyContent="center"
                onClick={handleClick(CampaignTypes.OIC)}
              >
                <OicLeadIcon
                  width="20"
                  height="20"
                  color={tabId === CampaignTypes.OIC ? "#fff" : "#000000"}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                  }}
                >
                  OIC
                </Typography>
              </Stack>
              <Stack>
                <Button
                  variant="success"
                  size="small"
                  style={{
                    color: "green",
                    border: "1px solid green",
                    height: "50px",
                    fontSize: "13px",
                  }}
                  onClick={handleOpen}
                >
                  New Campaign
                </Button>
              </Stack>
            </Grid>
          </Grid>
          {open && <JotformModal open={open} setOpen={setOpen} />}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CampaignDashboard style={{ width: "100%" }} stats={info} campaignType={tabId} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container>
              <Grid
                container
                padding={2}
                alignItems="center"
                gap={2}
                direction={{ xs: "row", md: "row" }}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    gap={2}
                    direction={{ xs: "column", md: "row" }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        paddingTop: "0.5%",
                        paddingBottom: "0.5%",
                        paddingLeft: "0.5%",
                        border: "1px solid #e4e4e7",
                      }}
                      alignItems="center"
                    >
                      <Icon sx={{ color: "#71717a", marginRight: "3px" }}>search</Icon>
                      <input
                        style={{
                          border: "0",
                          background: "white",
                          outline: "none",
                        }}
                        type="text"
                        value={search}
                        placeholder="Search"
                        onChange={onSearchChange}
                      />
                    </Stack>
                    <div className={classes.filters}>
                      <MultiSelect
                        key={status.length}
                        className={classes.datePicker}
                        placeholder="Status"
                        isMulti
                        options={statusOptions}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        value={statusDisplayValue}
                        onChange={handleStatusChange}
                        allowSelectAll={false}
                      />
                    </div>
                    <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                      <Typography>Active Campaigns: {info?.active_campaigns}</Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {loadingInfoTable ? (
                    <Loader />
                  ) : (
                    <>
                      <CampaignTable
                        key={tabId}
                        rows={campaigns && campaigns ? campaigns : []}
                        customerId={customerUser.customer.id}
                        search={search}
                        campaignType={tabId}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Campaign;
