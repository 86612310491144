/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DynamicCals from "./DynamicCalc";
import SimpleTabInfo from "./SimpleTabInfo";

function DynamicTabInfo({ customerUser }) {
  const [refreshData, setRefreshData] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleClick = (id) => () => {
    setTabValue(id);
  };

  useEffect(() => {
    const storedTabIndex = localStorage.getItem("activeKpiTabIndex");
    if (storedTabIndex !== null) {
      setTabValue(parseInt(storedTabIndex, 10));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activeKpiTabIndex", tabValue.toString());
  }, [tabValue]);

  const useStyles = makeStyles(() => ({
    typographyLabelStyle: {
      fontSize: "15px",
    },
    suiBoxStyle: {
      fontSize: "13px",
    },
    main: {
      minHeight: "60px",
      display: "flex",
      justifyContent: "space-between",
    },
    filters: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      "@media (max-width: 580px)": {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    "@media (max-width: 900px)": {
      main: {
        display: "block",
        flexDirection: "column",
      },
      filters: {
        display: "block",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 0,
      },
      filterTab: {
        display: "block",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    datePicker: {
      width: "250px",
      marginBottom: "20px",
      "@media (max-width: 380px)": {
        width: "180px",
      },
    },
    companyInfoWrapper: {
      flex: "left",
      width: "100%",
      padding: "20px",
      overflow: "auto",
    },
    employeeInfoWrapper: {
      flex: "right",
      width: "100%",
      border: "1px solid white",
      padding: "20px",
      backgroundColor: "#f0f0f0",
      overflow: "auto",
    },
    row: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
    },
    "@media (min-width: 1441px)": {
      row: {
        flexDirection: "row",
        height: "95vh",
      },
      companyInfoWrapper: {
        width: "65%",
      },
      employeeInfoWrapper: {
        width: "35%",
      },
    },
    "@media (max-width: 1440px)": {
      row: {
        flexDirection: "row",
        height: "95vh",
      },
      companyInfoWrapper: {
        width: "60%",
      },
      employeeInfoWrapper: {
        width: "40%",
      },
    },
    "@media (max-width: 768px)": {
      row: {
        flexDirection: "column",
        height: "auto",
      },
      companyInfoWrapper: {
        width: "100%",
      },
      employeeInfoWrapper: {
        width: "100%",
      },
    },
    info: {
      display: "flex",
      marginBottom: "5px",
    },
    input: {
      maxWidth: "100px",
    },
  }));
  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#5F5F5F",
            fontFamily: "Poppins",
            padding: "8px 16px 8px 18px",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "-2%",
            fontSize: "13px",
            fontWeight: "600",
            minHeight: "5px",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              padding: "8px 16px 8px 18px",
              marginRight: "-2%",
              zIndex: "1",
              fontSize: "13px",
              fontWeight: "600",
            },
          },
        },
      },
    },
  });

  const handleValueChanged = () => {
    setRefreshData(!refreshData);
  };

  return (
    <div>
      <Grid container>
        <Grid item lg={12} md={12}>
          <Card
            sx={{
              border: "1px solid #d1d5db",
              padding: "10px",
              borderRadius: "10px",
              boxShadow: "none",
              marginTop: "10px",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 1,
                padding: "7px",
                paddingBottom: "0 !important",
              }}
            >
              <InfoOutlinedIcon sx={{ color: "#6b7280" }} />
              <div>
                <Typography variant="h6" fontSize={16} fontWeight="bold">
                  Welcome to the ROI Calculator
                </Typography>
                <Typography fontSize={14} color="gray">
                  Fill in your sales metrics below to calculate your ROI and get insights into your
                  sales performance. Hover over any metric for more information.
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Card
        sx={{
          borderRadius: "5px",
          boxShadow: "none",
          padding: "24px",
          paddingBottom: 0,
          overflow: "visible",
          marginTop: "20px",
        }}
      >
        <div className={classes.main}>
          <ThemeProvider theme={theme}>
            <Card
              sx={{
                boxShadow: "none",
              }}
            >
              <Stack
                className={classes.filterTab}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack className={classes.filters} width="100%" direction="row" alignItems="center">
                  <Grid
                    display={{ xs: "block", sm: "flex", md: "flex", lg: "flex" }}
                    gap={1}
                    mr={2}
                    p={0}
                  >
                    <Stack
                      sx={{
                        padding: "10px",
                        backgroundColor: tabValue === 0 ? "#FF4D00D9" : "#FFFFFF",
                        borderRadius: "5px",
                        marginRight: "1%",
                        marginBottom: "1%",
                        cursor: "pointer",
                        color: tabValue === 0 ? "#fff" : "#000000",
                        textAlign: "center",
                        border: "1px solid #e4e4e7",
                        gap: "5px",
                      }}
                      xs={{ flexGrow: 1 }}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      onClick={handleClick(0)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-calculator mr-2 h-4 w-4"
                      >
                        <rect width="16" height="20" x="4" y="2" rx="2" />
                        <line x1="8" x2="16" y1="6" y2="6" />
                        <line x1="16" x2="16" y1="14" y2="18" />
                        <path d="M16 10h.01" />
                        <path d="M12 10h.01" />
                        <path d="M8 10h.01" />
                        <path d="M12 14h.01" />
                        <path d="M8 14h.01" />
                        <path d="M12 18h.01" />
                        <path d="M8 18h.01" />
                      </svg>

                      <Typography
                        sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins" }}
                      >
                        Calculator
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        padding: "10px",
                        backgroundColor: tabValue === 1 ? "#FF4D00D9" : "#FFFFFF",
                        borderRadius: "5px",
                        marginRight: "1%",
                        marginBottom: "1%",
                        cursor: "pointer",
                        color: tabValue === 1 ? "#fff" : "#000000",
                        textAlign: "center",
                        minWidth: "120px",
                        gap: "5px",
                        border: "1px solid #e4e4e7",
                      }}
                      xs={{ flexGrow: 1 }}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      onClick={handleClick(1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-target mr-2 h-4 w-4"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <circle cx="12" cy="12" r="6" />
                        <circle cx="12" cy="12" r="2" />
                      </svg>
                      <Typography
                        sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins" }}
                      >
                        Roi Data
                      </Typography>
                    </Stack>
                  </Grid>
                </Stack>
              </Stack>
            </Card>
          </ThemeProvider>
        </div>
      </Card>
      <div>
        {tabValue === 0 && (
          <ThemeProvider theme={theme}>
            <div>
              <SimpleTabInfo customerUser={customerUser} />
            </div>
          </ThemeProvider>
        )}
        {tabValue === 1 && (
          <DynamicCals key={tabValue} customerUser={customerUser} refreshData={refreshData} />
        )}
      </div>
    </div>
  );
}

export default DynamicTabInfo;
DynamicTabInfo.propTypes = {
  customerUser: PropTypes.any.isRequired,
};
