import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Pagination, Select, MenuItem, Stack } from "@mui/material";
import { Grid } from "@material-ui/core";
import CampaignTypes from "common/constants/campaign-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TableSort from "components/authentication_v2/TableSort";
import CampaignChart from "./CampaignChart";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function CampaignTable({ rows, search, campaignType }) {
  const history = useHistory();
  const useStyles = makeStyles(() => ({
    TableContainer: {
      minHeight: "25vh",
      maxHeight: "50vh",
      overflow: "auto",
      border: "1px solid #e0e0e0",
      borderRadius: "10px",
    },
    table: {
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: "500",
      borderTop: "1px solid #5F5F5F26",
      color: "#010101",
    },
    clickableCell: {
      cursor: "pointer",
    },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#626161",
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "Poppins",
            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: "#010101",
              fontSize: "14px",
              fontWeight: "700",
              fontFamily: "Poppins",
            },
          },
        },
      },
    },
  });
  const [page, setPage] = useState(0);
  const [descriptions, showDescriptions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortingOrderBy, setSortingOrderBy] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const openCampaign = (campaign) => {
    if (descriptions.includes(campaign.id)) {
      showDescriptions(descriptions.filter((d) => d !== campaign.id));
    } else {
      showDescriptions([...descriptions, campaign.id]);
    }
  };

  const openCampaignLeads = (campaign) => {
    history.push({
      pathname: `/${campaignType}`,
      state: {
        campaignId: campaign.id,
      },
    });
  };

  const headerMapping =
    campaignType === CampaignTypes.APPOINTMENTS
      ? {
          Name: "name",
          Link: "type",
          Status: "status",
          "Raw Leads": "num_of_raw_leads",
          "Appointments Limit": "appointmentsLimit",
          "Successful Appointments": "numOfSuccessfulAppointments",
          Difference: "difference",
        }
      : {
          Name: "name",
          Link: "type",
          Status: "status",
          "Raw Leads": "num_of_raw_leads",
          "Appointments Limit": "appointmentsLimit",
        };

  const handleSort = (field) => {
    if (sortingOrderBy === field) {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy(field);
      setSortingOrder("asc");
    }
  };

  const safeRows = Array.isArray(rows) ? rows : [];
  const filterRows = search
    ? safeRows.filter((row) =>
        Object.values(row).join(" ").toLowerCase().includes(search.toLowerCase())
      )
    : safeRows;
  const sortedRows = stableSort(filterRows, getComparator(sortingOrder, sortingOrderBy));
  const paginationRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer className={classes.TableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(headerMapping).map((headCell) => (
                <TableSort
                  key={headCell}
                  align="left"
                  sorted={sortingOrderBy === headerMapping[headCell] ? sortingOrder : "none"}
                  onClick={() => handleSort(headerMapping[headCell])}
                >
                  {headCell}
                </TableSort>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationRows.map((item) => (
              <>
                <TableRow key={item}>
                  <TableCell
                    className={classes.clickableCell}
                    onClick={() => openCampaign({ id: item.id })}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `
                            ${
                              item.description
                                ? `<a style="color:#FF8042">${item.name}</a>`
                                : item.name
                            }
                          `,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.table}
                    style={{ cursor: "pointer" }}
                    onClick={() => openCampaignLeads({ id: item.id })}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<a style="color:#ff6600; text-transform: capitalize;">${
                          item.type === "oic" ? "OIC Leads" : item.type
                        }</a>`,
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.table}>{item.status.replace("_", " ")}</TableCell>
                  <TableCell className={classes.table}>{item.num_of_raw_leads}</TableCell>
                  <TableCell className={classes.table}>{item.appointmentsLimit}</TableCell>
                  {campaignType === CampaignTypes.APPOINTMENTS && (
                    <TableCell className={classes.table}>
                      {item.numOfSuccessfulAppointments}
                    </TableCell>
                  )}
                  <TableCell className={classes.table}>{Math.abs(item.difference)}</TableCell>
                </TableRow>
                {descriptions.includes(item.id) && (
                  <TableRow key={item.id} backgroundColor="gray">
                    <TableCell className={classes.table} colSpan={6}>
                      <CampaignChart campaign={item} />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(e.target.value)}
            displayEmpty
            variant="standard"
          >
            {[5, 10, 25, 50, 100].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          <Pagination
            count={Math.ceil(filterRows.length / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => setPage(newPage - 1)}
          />
        </Stack>
      </Grid>
    </ThemeProvider>
  );
}

CampaignTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  campaignType: PropTypes.func.isRequired,
  search: PropTypes.any,
};

CampaignTable.defaultProps = {
  rows: [],
  search: "",
};

export default CampaignTable;
